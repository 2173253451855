// Pulling these images from Unsplash
// Toshi the dog from https://unsplash.com/@charlesdeluvio - what a funny dog...

.bg-login-image {
  background: url(../img/images/bg-login.jpg) no-repeat top center/cover content-box;
}

.bg-register-image {
  background: url(../img/images/bg-login.jpg) no-repeat top center/cover content-box;
}

.bg-password-image {
  background: url(../img/images/bg-login.jpg) no-repeat top center/cover content-box;
}

form.user {

  .custom-checkbox.small {
    label {
      line-height: 1.5rem;
    }
  }

  .form-control-user {
    height: calc(1.5em + .75rem + 2px);
    font-size: 0.8rem;
    border-radius: 10rem;
    padding: 1.5rem 1rem;
  }

  .btn-user {
    display: block;
    font-size: 0.8rem;
    color: $white;
    padding: 0.75rem 1rem;
    border-radius: 10rem;
  }

}

.btn-google {
  @include button-variant($brand-google, $white);
}

.btn-facebook {
  @include button-variant($brand-facebook, $white);
}
