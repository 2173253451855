@import 'assets/scss/datepicker';


/* You can add global styles to this file, and also import other style files */
#countryCode {
  width: 70px;
}


.background-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(to right, #ad46e4e1, #3f92ffe1),
    url('./assets/img/images/delete-account-bg-img.jpg') no-repeat center center fixed;
  background-size: cover;
  z-index: -1;
  /* Ensure the background stays behind other content */
}

.transparent-input{
  color: #fff !important;
  background-color:transparent !important;
  border: 1px solid #fff;
  border-radius: 50px;
}

.transparent-input::placeholder {
  color: rgb(255, 255, 255, 0.7) !important; /* Semi-transparent white for better contrast */
}

.transparent-input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px transparent inset !important; 
  transition: background-color 5000s ease-in-out 0s; 
  -webkit-text-fill-color: white !important;
}

.btn-white {
  color: #4e73df;
  background-color: #fff;
  border-color: #fff;
}

.btn-white:hover {
  color: #4e73df;
  background-color: #fff;
  border-color: #fff;
}

.custom-control{
  &-checkbox{
    &-no{
      margin-right: 0.5rem;
      &::before,
      &::after{
        content: none !important;
      }
    }
  }
}

.user-label{
  $size: 6.25rem;
  width: $size;
  height: $size;
  border-radius: 50%;

  &-edit{
    display: inline-block;
    position: absolute;
    bottom: 0;
    right: 0;

    &-btn {
      padding: 0.5rem !important;
      line-height: 0 !important;
      border-radius: 50% !important;

      &-icon{
        font-size: 0.5rem;
      }
    }
  }
}