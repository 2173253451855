.form-group {
    margin-bottom: 1rem;
}

.dropdown-item.active, .dropdown-item:active {
    &.text-primary{
        color: #fff !important;
    }
}

.input-group.is-login-page {
    @extend .rounded-pill;
    border: 1px solid $input-border-color;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;

    &>* {
        border: none !important;
    }

    &:focus-within {
        border-color: $input-focus-border-color;
    }
}

.border-btm {
    border-bottom: 1px solid #efefef;
    padding-bottom: 15px;
}

.brb-rl {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.brt-rl {
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
}

.img-res {
    max-width: 200px;
    max-height: 100px;
    margin-top: 5px;
}

.img-size-100 {
    width: 100%;
    /* max-width: 100px; */
    height: 100px;
    object-fit: cover;
}

.lightbox {
    display: flex !important;
    flex-direction: column-reverse;
}

.lb-dataContainer {
    padding-bottom: 10px;
}