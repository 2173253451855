.bs-datepicker-container {
    padding: 0;
}

.app-theme-primary {

    .bs-datepicker-head,
    .bs-datepicker-body table td span.selected,
    .bs-datepicker-body table td.selected span,
    .bs-datepicker-body table td span[class*=select-]:after,
    .bs-datepicker-body table td[class*=select-] span:after {
        background-color: #4e73df;
    }

    .bs-datepicker-body {
        table {
            td.week span {
                color: #4e73df;
            }
        }
    }
}