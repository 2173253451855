
/* Table */

.table th {
    white-space: pre;
    border-top: none !important;
}

.items-list {
    padding: 0;
}
.items-list li{
    list-style: none;
}

.reset-text{
    max-width: 19rem;
}

.btn-rounded-right {
    border-top-right-radius: 50rem !important;
    border-bottom-right-radius: 50rem !important;
    
}

.btn-rounded-left {
    border-top-left-radius: 50rem !important;
    border-bottom-left-radius: 50rem !important;
    
}

.btn:focus {
   outline: none !important;
   box-shadow: none !important;
}

input:focus {
    outline: none !important;
    box-shadow: none !important;
}

input:-webkit-autofill { 
    -webkit-box-shadow: 0 0 0 1000px white inset !important;
  }